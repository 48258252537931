import { Component, OnInit } from '@angular/core';
import { BackofficeManagerService } from '@app/services/backoffice-manager.service';
import { ResumService } from './services/resum.service';
import { finalize, forkJoin } from 'rxjs';
import { ResponseResumModel } from './models/response.model';
import { IWidgetHeader } from './interfaces/widget-header.interface';

@Component({
  selector: 'app-resum',
  templateUrl: './resum.component.html',
  styleUrls: ['./resum.component.scss'],
})
export class ResumComponent implements OnInit {
  public pagesHeader: Record<string, IWidgetHeader> = {};

  constructor(
    private resumService: ResumService,
    private backofficeManagerService: BackofficeManagerService
  ) {}

  ngOnInit() {
    this.backofficeManagerService.addPetition(true);
    forkJoin({
      tests: this.resumService.getTests(),
      clinicalReports: this.resumService.getClinicalReports(),
      inmunitzacions: this.resumService.getImmunizations(),
      prescriptions: this.resumService.getPrescriptions(),
    })
      .pipe(
        finalize(() => {
          this.backofficeManagerService.removePetition(true);
        })
      )
      .subscribe({
        next: responses =>
          this.initPagesHeader(
            responses.tests.result.listaRespostaResum,
            responses.clinicalReports.result.listaRespostaResum,
            responses.inmunitzacions.result.listaRespostaResum,
            responses.prescriptions.result.listaRespostaResum
          ),
        error: error => console.error('Error en una o más peticiones:', error),
      });
  }

  private initPagesHeader(
    tests: ResponseResumModel[],
    clinicalReports: ResponseResumModel[],
    inmunitzacions: ResponseResumModel[],
    prescriptions: ResponseResumModel[]
  ) {
    this.pagesHeader = {
      proves: {
        title: 'Proves',
        path: 'proves',
        hiddenAttributes: false,
        data: tests,
      },
      informesClinics: {
        title: 'Informes Clínics',
        path: 'informes-clinics',
        hiddenAttributes: false,
        data: clinicalReports,
      },
      immunitzacions: {
        title: 'Immunitzacions',
        path: 'immunitzacions',
        hiddenAttributes: false,
        data: inmunitzacions,
      },
      prescripcions: {
        title: 'Prescripció Vigent',
        path: 'prescripcions',
        hiddenAttributes: true,
        data: prescriptions,
      },
    };
  }
}
