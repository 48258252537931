<app-loading
  *ngIf="loading"
  [fullscreen]="isFullscreen"
  [transparent]="false"></app-loading>
<app-loading
  *ngIf="transparentLoading"
  [fullscreen]="isFullscreen"
  [transparent]="true"></app-loading>
<app-local-loading
  [transparent]="true"
  *ngIf="localLoading"
  [width]="localComponentSize.width"
  [height]="localComponentSize.height"></app-local-loading>
<div class="container">
  <app-header></app-header>
  <div class="main">
    <mat-sidenav-container class="sidenav-container">
      <mat-drawer-container fxFlex autosize class="drawer-container">
        <mat-drawer #drawer mode="side" position="end">
          <app-sidebar #sidebar></app-sidebar>
        </mat-drawer>

        <div class="main-content">
          <router-outlet></router-outlet>
        </div>
      </mat-drawer-container>
    </mat-sidenav-container>
  </div>
  <app-footer></app-footer>
</div>
