import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HesVirtualScrollerComponent } from './hes-virtual-scroller.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [HesVirtualScrollerComponent],
  imports: [CommonModule, ScrollingModule],
  exports: [HesVirtualScrollerComponent],
})
export class HesVirtualScrollerModule {}
