import {
  IResponse,
  IResponseBase,
  IResponseResum,
  IResult,
  IResultList,
} from '../interfaces/response.interface';

export class ResultListModel {
  codi: string;
  descripcio: string;

  constructor(data: IResultList) {
    this.codi = data.codi;
    this.descripcio = data.descripcio;
  }
}

export class ResponseModel {
  resultCode: string;
  llistaResultat: ResultListModel[] = [];

  constructor(data: IResponse) {
    this.resultCode = data.resultCode;

    if (data.llistaResultat)
      this.llistaResultat = data.llistaResultat.map(
        result => new ResultListModel(result)
      );
  }
}

export class ResponseResumModel {
  data: string;
  desc: string;
  codi?: string;
  doc?: string;
  icona?: string;
  diagnostic?: string;
  unitatProveidora?: string;
  codiAgrup1?: string;
  descripAgrup1?: string;
  codiAgrup2?: string;
  descripAgrup2?: string;
  pdfConsultable?: boolean;
  provaCovid?: boolean;
  pauta?: string;
  indicacio?: string;
  tipusTractament?: string;
  tipusPrescripcio?: string;
  img?: string;

  constructor(data: IResponseResum) {
    this.data = data.data;
    this.desc = data.desc;
    this.codi = data.codi;
    this.doc = data.doc;
    this.icona = data.icona;
    this.diagnostic = data.diagnostic;
    this.unitatProveidora = data.unitatProveidora;
    this.codiAgrup1 = data.codiAgrup1;
    this.descripAgrup1 = data.descripAgrup1;
    this.codiAgrup2 = data.codiAgrup2;
    this.descripAgrup2 = data.descripAgrup2;
    this.pdfConsultable = data.pdfConsultable;
    this.provaCovid = data.provaCovid;
    this.pauta = data.pauta;
    this.indicacio = data.indicacio;
    this.tipusTractament = data.tipusTractament;
    this.tipusPrescripcio = data.tipusPrescripcio;
    this.img = data.img;
  }
}

export class ResultModel {
  resposta: ResponseModel;
  listaRespostaResum: ResponseResumModel[];

  constructor(data: IResult) {
    this.resposta = new ResponseModel(data.resposta);
    this.listaRespostaResum = data.listaRespostaResum.map(
      res => new ResponseResumModel(res)
    );
  }
}

export class ResponseBaseModel {
  code: number;
  message: string;
  result: ResultModel;

  constructor(data: IResponseBase) {
    this.code = data.code;
    this.message = data.message;
    this.result = new ResultModel(data.result);
  }
}
