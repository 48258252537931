import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { LocalLoadingComponent } from './local-loading.component';

/**
 * LocalLoadingModule
 */
@NgModule({
  declarations: [LocalLoadingComponent],
  imports: [CommonModule],
  exports: [LocalLoadingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LocalLoadingModule {}
