import * as CryptoJS from 'crypto-js';

export class B64Helper {
  public static encode(str: any) {
    const words = CryptoJS.enc.Utf8.parse(str);
    return CryptoJS.enc.Base64.stringify(words);
  }

  public static decode(base64str: any) {
    const words = CryptoJS.enc.Base64.parse(base64str);
    return CryptoJS.enc.Utf8.stringify(words);
  }

  public static base64ToArrayBuffer(base64: any) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
