<header class="dss-header">
  <div class="dss-header__identity">
    <div class="dss-header__identity-content">
      <img
        routerLink=""
        alt="header-logo"
        class="dss-header__logo"
        src="../../../assets/images/gencat-logotip-default.svg" />
      <div class="dss-header__divider dss-header__divider--only-device"></div>
      <h4 class="dss-header__project-name">Procés obstètric</h4>

      <ng-container *ngIf="authService.userDataObservable | async as userData">
        <div class="dss-header__divider"></div>
        <div
          class="dss-header__person"
          tabindex="1"
          [matMenuTriggerFor]="patientMenu">
          <dss-avatar size="lg"></dss-avatar>
          <div class="dss-header__person-content">
            <span class="dss-header__person-name">{{
              userData.patient?.name
            }}</span>
            <span class="dss-header__person-details"
              >CIP: {{ userData.patient?.cip }} - Dona -
              {{ userData.patient?.age }} anys</span
            >
          </div>
          <em class="dss-header__person-arrow material-icons-round">
            keyboard_arrow_down
          </em>
        </div>
        <mat-menu class="dss-action-menu" #patientMenu="matMenu">
          <li tabindex="1" class="dss-action-menu__item">
            <span class="material-symbols-rounded dss-icon--md"> mail </span>
            prueba@gmail.com
          </li>
          <li tabindex="1" class="dss-action-menu__item">
            <span class="material-symbols-rounded dss-icon--md"> call </span>
            123456789
          </li>
        </mat-menu>
      </ng-container>
    </div>
  </div>
  <div class="dss-header__navigation">
    <div class="dss-header__list-item">
      <div class="dss-header__item" tabindex="1">
        <em class="dss-header__item-icon material-symbols-rounded dss-icon--md">
          help_outline
        </em>
        <span class="dss-subtitle--sm">Ajuda</span>
      </div>
    </div>
    <div
      class="dss-header__list-item"
      *ngIf="authService.getAccessToken() !== ''">
      <div class="dss-header__item" tabindex="1" (click)="logout()">
        <button class="dss-icon-button dss-icon-button--sm header-item-custom">
          <em
            class="dss-header__item-icon material-symbols-rounded dss-icon--md"
            >logout</em
          >
          <span class="dss-subtitle--sm header-item-custom">Sortir</span>
        </button>
      </div>
    </div>

    <ng-container *ngIf="authService.userDataObservable | async as userData">
      <div class="dss-header__divider"></div>
      <span class="dss-header__menu-icon material-icons-outlined">menu</span>

      <div class="dss-header__person" tabindex="1" [matMenuTriggerFor]="menu">
        <dss-avatar size="lg"></dss-avatar>
        <div class="dss-header__person-content">
          <span class="dss-header__person-name"
            >{{ userData.nombre }} {{ userData.apellidos }}</span
          >
        </div>
        <em class="dss-header__person-arrow material-icons-round">
          keyboard_arrow_down
        </em>
      </div>
      <mat-menu class="dss-action-menu" #menu="matMenu">
        <li tabindex="1" class="dss-action-menu__item">
          <span class="material-symbols-rounded dss-icon--md"> mail </span>
          email@gmail.com
        </li>
        <li tabindex="1" class="dss-action-menu__item">
          <span class="material-symbols-rounded dss-icon--md"> call </span>
          639641111
        </li>
      </mat-menu>
    </ng-container>
  </div>
</header>
