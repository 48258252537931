import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { TokenInterceptorService } from './core/token-interceptor.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';

import { LayoutModule } from './layout/layout.module';
import { LoadingModule } from './shared/components/loading/loading.module';
import { LocalLoadingModule } from './shared/components/local-loading/local-loading.module';
import {
  popperVariation,
  provideTippyConfig,
  withContextMenuVariation,
} from '@ngneat/helipopper';

import { sticky } from 'tippy.js';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { registerLocaleData } from '@angular/common';
import localeEsCAT from '@angular/common/locales/ca';
import { HesConfigService } from './core/services/hes-config.service';
import '@salut/design-system-salut';

export const DateFormats = {
  parse: {
    dateInput: ['DD-MM-YYYY'],
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const hesTooltipVariation = {
  theme: 'hes',
  arrow: true,
  animation: 'scale',
  trigger: 'mouseenter',
  offset: [0, 4] as [number, number],
};

export const hesDarkTooltipVariation = {
  theme: 'hes-dark',
  arrow: true,
  animation: 'scale',
  trigger: 'mouseenter',
  offset: [0, 4] as [number, number],
};

export const hesDarkSmallTooltipVariation = {
  theme: 'hes-dark-small',
  arrow: true,
  animation: 'scale',
  trigger: 'mouseenter',
  offset: [0, 4] as [number, number],
};

export const hesSmallTooltipVariation = {
  theme: 'hes-small',
  arrow: true,
  animation: 'scale',
  trigger: 'mouseenter',
  offset: [0, 4] as [number, number],
};

export const hesMenuVariation = {
  theme: 'hes-menu',
  arrow: true,
  animation: 'scale',
  trigger: 'mouseenter',
  offset: [0, 4] as [number, number],
};

export const sliderVariationColor1 = {
  theme: 'hes-slider-color-1',
  arrow: true,
  animation: 'scale',
  offset: [0, 10] as [number, number],
  sticky: true,
};

export const sliderVariationColor2 = {
  theme: 'hes-slider-color-2',
  arrow: true,
  animation: 'scale',
  offset: [0, 10] as [number, number],
  sticky: true,
};

export const sliderVariationColor3 = {
  theme: 'hes-slider-color-3',
  arrow: true,
  animation: 'scale',
  offset: [0, 10] as [number, number],
  sticky: true,
};

export const sliderVariationColor4 = {
  theme: 'hes-slider-color-4',
  arrow: true,
  animation: 'scale',
  offset: [0, 10] as [number, number],
  sticky: true,
};

export const sliderVariationColor5 = {
  theme: 'hes-slider-color-5',
  arrow: true,
  animation: 'scale',
  offset: [0, 10] as [number, number],
  sticky: true,
};

export const sliderVariationColor6 = {
  theme: 'hes-slider-color-6',
  arrow: true,
  animation: 'scale',
  offset: [0, 10] as [number, number],
  sticky: true,
};

export const sliderVariationColor7 = {
  theme: 'hes-slider-color-7',
  arrow: true,
  animation: 'scale',
  offset: [0, 10] as [number, number],
  sticky: true,
};

registerLocaleData(localeEsCAT);

export function LoadConfiguration(appConfigService: HesConfigService) {
  return () => appConfigService.loadConfiguration();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    MatIconModule,
    MatSidenavModule,
    LoadingModule,
    LocalLoadingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: LoadConfiguration,
      deps: [HesConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: hesTooltipVariation,
        popper: popperVariation,
        darkTooltip: hesDarkTooltipVariation,
        smallTooltip: hesSmallTooltipVariation,
        darkSmallTooltip: hesDarkSmallTooltipVariation,
        menuVariation: hesMenuVariation,
        sliderVariation1: sliderVariationColor1,
        sliderVariation2: sliderVariationColor2,
        sliderVariation3: sliderVariationColor3,
        sliderVariation4: sliderVariationColor4,
        sliderVariation5: sliderVariationColor5,
        sliderVariation6: sliderVariationColor6,
        sliderVariation7: sliderVariationColor7,
        menu: {
          ...popperVariation,
          appendTo: 'parent',
          arrow: false,
          offset: [0, 0],
        },
        contextMenu: withContextMenuVariation(popperVariation),
        popperBorder: {
          ...popperVariation,
          theme: 'light-border',
        },
      },
      plugins: [sticky],
    }),
    { provide: LOCALE_ID, useValue: 'ca' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    { provide: MAT_DATE_LOCALE, useValue: 'ca' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
