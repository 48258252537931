export class LocationUtil {
  public static getBaseHref() {
    if (document.baseURI) {
      return document.baseURI;
    }

    // IE 11
    const baseTags = document.getElementsByTagName('base');
    if (baseTags && baseTags.length > 0) {
      return baseTags[0].href;
    }

    console.log(window.location.hostname, window.location.href);
    return window.location.href;
  }
}
