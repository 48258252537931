import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { ScreensEnum } from '@app/enums/screens.enum';
import { ObstetricService } from '@app/pages/obstetric/services/obstetric.service';
import { ExitModalComponent } from '@app/shared/components/exit-modal/exit-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    public authService: AuthService,
    private _router: Router,
    private _obstetricService: ObstetricService,
    private _mDialog: MatDialog
  ) {
    this.authService.resetUserData();
    this.authService.resetAuthData();
  }

  logout() {
    const screensOnEditMode = this._obstetricService.getScreensOnEditMode();

    if (screensOnEditMode.length > 0) {
      this._openDeleteModal(screensOnEditMode).subscribe(discardChanges => {
        if (discardChanges) this._resetAllData();

        this._closeEcapFram();
      });
    } else {
      this._resetAllData();
      this._closeEcapFram();
    }
  }

  private _openDeleteModal(screensOnEditMode: ScreensEnum[]) {
    return this._mDialog
      .open(ExitModalComponent, {
        width: '528px',
        data: screensOnEditMode,
      })
      .afterClosed();
  }

  private _resetAllData() {
    this.authService.resetUserData();
    this.authService.resetAuthData();
    this._obstetricService.resetAllStores();
    this._router.navigate(['/']);
  }

  private _closeEcapFram() {
    window.parent.postMessage('sortir', '*');
  }
}
