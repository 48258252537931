<footer class="dss-footer__container">
  <div class="dss-footer__logo-content">
    <img
      alt="footer-logo"
      src="../../../assets/images/gencat-logotip-default.svg" />
  </div>
  <div class="dss-footer__content">
    <span class="dss-legend--sm">Historial Electrònic de Salut (HES)</span>
    <div class="dss-footer__link-content"></div>
  </div>
</footer>
