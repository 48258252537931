<div class="resum">
  <app-widget [widgetHeader]="pagesHeader['proves']">
    <app-widget-content
      *ngIf="pagesHeader['proves']"
      [widgetContentData]="
        pagesHeader['proves'].data ?? []
      "></app-widget-content>
  </app-widget>
  <app-widget [widgetHeader]="pagesHeader['informesClinics']">
    <app-widget-content
      *ngIf="pagesHeader['informesClinics']"
      [widgetContentData]="
        pagesHeader['informesClinics'].data ?? []
      "></app-widget-content>
  </app-widget>
  <app-widget [widgetHeader]="pagesHeader['immunitzacions']">
    <app-widget-content
      *ngIf="pagesHeader['immunitzacions']"
      [widgetContentData]="
        pagesHeader['immunitzacions'].data ?? []
      "></app-widget-content>
  </app-widget>
  <app-widget [widgetHeader]="pagesHeader['prescripcions']">
    <app-widget-content
      *ngIf="pagesHeader['prescripcions']"
      [isPrescriptionPage]="true"
      [widgetContentData]="
        pagesHeader['prescripcions'].data ?? []
      "></app-widget-content>
  </app-widget>
</div>
