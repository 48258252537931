import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExitModalComponent } from './exit-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ExitModalComponent],
  imports: [CommonModule, MatDialogModule, RouterModule],
  exports: [ExitModalComponent],
})
export class ExitModalModule {}
