import { Injectable } from '@angular/core';
import { StorageTypeEnum } from '@app/enums/storage.enum';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _prefix = 'obstetric_';
  private _storage: StorageTypeEnum = StorageTypeEnum.SESSION;

  constructor(private _cookieService: CookieService) {}

  public setStorageType(storageType: StorageTypeEnum) {
    this._storage = storageType;
  }

  public setItem(key: string, value: any): void {
    const keyWithPrefix = `${this._prefix}${key}`;
    if (this._storage === StorageTypeEnum.SESSION) {
      sessionStorage.setItem(keyWithPrefix, value);
      return;
    }
    if (this._storage === StorageTypeEnum.LOCAL) {
      localStorage.setItem(keyWithPrefix, value);
      return;
    }
    if (this._storage === StorageTypeEnum.COOKIE) {
      this._cookieService.set(keyWithPrefix, value);
    }
  }

  public getItem(key: string): string {
    const keyWithPrefix = `${this._prefix}${key}`;
    if (this._storage === StorageTypeEnum.SESSION) {
      return sessionStorage.getItem(keyWithPrefix) ?? '';
    }
    if (this._storage === StorageTypeEnum.LOCAL) {
      return localStorage.getItem(keyWithPrefix) ?? '';
    }
    if (this._storage === StorageTypeEnum.COOKIE) {
      return this._cookieService.get(keyWithPrefix);
    }
    return '';
  }

  public removeItem(key: string): void {
    const keyWithPrefix = `${this._prefix}${key}`;
    if (this._storage === StorageTypeEnum.SESSION) {
      sessionStorage.removeItem(keyWithPrefix);
    }
    if (this._storage === StorageTypeEnum.LOCAL) {
      localStorage.removeItem(keyWithPrefix);
    }
    if (this._storage === StorageTypeEnum.COOKIE) {
      this._cookieService.delete(keyWithPrefix);
    }
  }
}
