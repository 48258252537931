import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Config } from '@app/core/config/config';
import { AuthService } from '@app/core/services/auth.service';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HesDocumentDownloaderService implements OnDestroy {
  private subscriptions = new Subscription();
  private _mpiPatientId = '';

  private _baseUrl: string = Config.api.baseUrl;
  constructor(
    private _http: HttpClient,
    private _authData: AuthService
  ) {
    this.subscriptions.add(
      this._authData.userDataObservable.subscribe(data => {
        this._mpiPatientId = data?.patient?.mpiPatientId ?? '';
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public downloadPdf(cpc: string): Observable<any> {
    const url = `${this._baseUrl}/schedule/document/${cpc}`;
    const options = {
      headers: new HttpHeaders().append('patientId', this._mpiPatientId),
    };
    return this._http.get(url, options);
  }

  public downloadImg(cpc: string): Observable<any> {
    const url = `${this._baseUrl}/imatgeDigital/`;
    const options = {
      headers: new HttpHeaders().append('X-HES-Pacient-CPC', cpc),
    };
    return this._http.get(url, options);
  }
}
