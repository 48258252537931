import { Component, Input } from '@angular/core';

/**
 * LoadingComponent
 */
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() transparent = false;
  @Input() fullscreen = false;
}
