import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface ISize {
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root',
})
export class BackofficeManagerService {
  #loadingSubject: Subject<boolean> = new Subject();
  #transparentLoadingSubject: Subject<boolean> = new Subject();
  #localLoadingSubject: Subject<boolean> = new Subject();
  #localComponentSizeSubject = new BehaviorSubject<ISize>({
    width: 0,
    height: 0,
  });
  #petitionCount = 0;
  #transparentPetitionCount = 0;
  #localPetitionCount = 0;
  loadingSubject$ = this.#loadingSubject.asObservable();
  transparentLoadingSubject$ = this.#transparentLoadingSubject.asObservable();
  localLoadingSubject$ = this.#localLoadingSubject.asObservable();
  localComponentSizeSubject = this.#localComponentSizeSubject.asObservable();

  /**
   * Adds a petition to the loading state.
   *
   * @param {boolean} transparent - Indicates if the petition is transparent.
   */
  addPetition(transparent: boolean) {
    if (transparent) {
      this.#transparentPetitionCount++;
    } else {
      this.#petitionCount++;
    }
    this.#emitLoadingSubjects();
  }

  /**
   * Adds a local petition to the loading state with the provided size.
   *
   * @param {ISize} size - The size of the local component.
   */
  addLocalPetition(size: ISize) {
    this.#localComponentSizeSubject.next(size);
    this.#localPetitionCount++;
    this.#emitLoadingSubjects();
  }

  /**
   * Removes a local petition from the loading state.
   */
  removeLocalPetition() {
    this.#localComponentSizeSubject.next({ width: 0, height: 0 });
    this.#localPetitionCount--;
    this.#emitLoadingSubjects();
  }

  /**
   * Removes a petition from the loading state.
   *
   * @param {boolean} transparent - Indicates if the petition is transparent.
   */
  removePetition(transparent: boolean) {
    if (transparent && this.#transparentPetitionCount > 0) {
      this.#transparentPetitionCount--;
    } else if (!transparent && this.#petitionCount > 0) {
      this.#petitionCount--;
    }
    this.#emitLoadingSubjects();
  }

  /**
   * Emits loading subjects.
   *
   * @private
   */
  #emitLoadingSubjects() {
    this.#loadingSubject.next(Boolean(this.#petitionCount));
    this.#transparentLoadingSubject.next(
      Boolean(this.#transparentPetitionCount && this.#petitionCount <= 0)
    );
    this.#localLoadingSubject.next(Boolean(this.#localPetitionCount));
  }
}
