import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'any' })
export class HesVirtualScrollerService {
  public dataScrolled?: any[];
  private dataAll?: any[];
  private currentPage = 0;
  private visibleItems?: number;

  public loadInitData(
    visibleItems: number,
    dataAll: any,
    itemsOffsetEnableScroll = 1
  ): void {
    this.visibleItems = visibleItems + itemsOffsetEnableScroll; // +1 needed to enable scroll in the first load
    this.dataAll = dataAll;
    this.dataScrolled = this.getPageData(
      this.dataAll,
      this.currentPage,
      this.visibleItems
    );
  }

  public loadMoreData(): void {
    this.currentPage++;
    const pageData = this.getPageData(
      this.dataAll,
      this.currentPage,
      this.visibleItems ?? 1
    );
    this.dataScrolled?.push(...pageData);
  }

  private getPageData(data: any, page: number, pageSize: number) {
    return data.slice(page * pageSize, page * pageSize + pageSize);
  }
}
