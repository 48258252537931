import { of, forkJoin, Observable, from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { sendHttpRequest } from './http-request.service';

export interface FetchResponse {
  serviceName: string;
  config: any;
}

export class ConfigurationLoaderService {
  static bootstrap(cfgUrl: string, conf: any): Observable<any> {
    let environment = of({});
    if (cfgUrl) {
      environment = from(
        sendHttpRequest({
          method: 'GET',
          url: cfgUrl,
        })
      ).pipe(
        catchError(error => {
          console.log(error);
          return of({});
        }),
        map(resp => {
          // load environment properties
          const envconf = JSON.parse(<string>resp);
          ConfigurationLoaderService.mapObject(envconf, conf);
          console.log(JSON.stringify(conf));
        }),
        mergeMap(resp => {
          if (conf.hasOwnProperty('import')) {
            return ConfigurationLoaderService.fetch(conf['import'], conf);
          } else {
            return of([]);
          }
        })
      );
    }

    return environment;
  }

  static fetch(urls: string[], conf: any): Observable<string[]> {
    const batch: any[] = [];
    urls.forEach(url => {
      batch.push(
        from(
          sendHttpRequest({
            method: 'GET',
            url: url,
          })
        )
      );
    });

    return forkJoin([batch]).pipe(
      map(responses => {
        const names: string[] = [];

        if (responses) {
          for (const item of responses) {
            const resp = <FetchResponse>JSON.parse(<string>item);
            conf[resp.serviceName] = resp.config;
            names.push(resp.serviceName);
          }
        }

        return names;
      })
    );
  }

  private static mapObject(fromObject: any, toObject: any): void {
    if (!fromObject) return;

    for (const prop in fromObject) {
      if (!fromObject.hasOwnProperty(prop)) continue;

      if (Array.isArray(fromObject[prop])) {
        toObject[prop] = toObject[prop] || [];
        ConfigurationLoaderService.mapArray(fromObject[prop], toObject[prop]);
      } else if (typeof fromObject[prop] === 'object') {
        toObject[prop] = toObject[prop] || {};
        ConfigurationLoaderService.mapObject(fromObject[prop], toObject[prop]);
      } else {
        toObject[prop] = fromObject[prop];
      }
    }
  }

  private static mapArray(fromArray: any, toArray: any): void {
    if (fromArray) {
      for (const item of fromArray) {
        toArray.push(item);
      }
    }
  }
}
