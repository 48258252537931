import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SidebarService } from '@app/services/sidebar.service';
import { MatDrawer } from '@angular/material/sidenav';
import {
  BackofficeManagerService,
  ISize,
} from '@app/services/backoffice-manager.service';
import '@salut/design-system-salut';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

  isFullscreen = false; //Revisar si se usará
  loading = false;
  localComponentSize!: ISize;
  transparentLoading = false;
  localLoading = false;
  subscriptions = new Subscription();

  constructor(
    private _sidebarService: SidebarService,
    private _backofficeManagerService: BackofficeManagerService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    this._authService.clearLocalStorageAuthData();

    this._sidebarService.sidebarSubject.subscribe(isToggle => {
      isToggle ? this.drawer.open() : this.drawer.close();
    });

    this.subscriptions.add(
      this._backofficeManagerService.localComponentSizeSubject.subscribe(
        size => {
          this.localComponentSize = size;
        }
      )
    );
    this.subscriptions.add(
      this._backofficeManagerService.loadingSubject$.subscribe(status => {
        this.loading = status;
        this._changeDetectorRef.detectChanges();
      })
    );
    this.subscriptions.add(
      this._backofficeManagerService.transparentLoadingSubject$.subscribe(
        status => {
          this.transparentLoading = status;
          this._changeDetectorRef.detectChanges();
        }
      )
    );
    this.subscriptions.add(
      this._backofficeManagerService.localLoadingSubject$.subscribe(status => {
        this.localLoading = status;
        this._changeDetectorRef.detectChanges();
      })
    );
  }
}
