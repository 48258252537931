import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  sidebarSubject = new BehaviorSubject<boolean>(true);

  get isExpanded() {
    return this.sidebarSubject.value;
  }

  toggleSidebar() {
    this.sidebarSubject.next(!this.sidebarSubject.value);
  }
}
