<div class="dss-modal">
  <div class="header-buttons">
    <em class="material-symbols-rounded report-icon">report</em>
    <button
      class="dss-icon-button dss-icon-button--default dss-icon-button--lg"
      (click)="dialogRef.close()">
      <span class="material-symbols-rounded">close</span>
    </button>
  </div>

  <div class="dss-modal__content">
    <h3 class="dss-modal__title dss-headline--md">Hi ha canvis sense desar</h3>

    <p class="dss-modal__description dss-body--md">
      Has fet modificacions que no has desat a les següents pantalles:
    </p>

    <div class="screens-container">
      <div
        *ngFor="let screen of screensOnEditMode"
        class="screen dss-body--sm dss-body--semibold">
        <span style="color: black">{{ screensInfoMapping[screen].name }}</span>
        <a
          (click)="
            navigateToScreen(
              screensInfoMapping[screen].route,
              screensInfoMapping[screen].screenIndex
            )
          ">
          Anar a la pantalla
        </a>
      </div>
    </div>
  </div>
  <div class="dss-modal__buttons">
    <button
      type="button"
      class="dss-button dss-button--lg dss-button--error"
      (click)="onClose(true)">
      Sortir sense desar
    </button>
  </div>
</div>
