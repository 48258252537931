<!-- eslint-disable @angular-eslint/template/alt-text -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<mat-accordion>
  <mat-expansion-panel
    [expanded]="false"
    hideToggle
    #widgetPanel
    class="hes-resum-widget__expansion-panel">
    <mat-expansion-panel-header
      #widgetHeaderPanel
      (click)="widgetHeaderPanel._toggle()"
      class="hes-resum-widget__header">
      <div class="hes-resum-widget__descriptor-container">
        <mat-card-title class="hes-resum-widget__title">{{
          widgetHeader?.title
        }}</mat-card-title>

        <span
          *ngIf="!widgetHeader?.hiddenAttributes"
          class="hes-resum-widget__months"
          >(12m)</span
        >
        <span class="hes-resum-widget__results-count">
          <span>(</span>
          <span>
            <ng-template [ngIf]="widgetHeader?.data?.length ?? 0 > 0">
              {{ widgetHeader?.data?.length }}
            </ng-template>
            <ng-template [ngIf]="widgetHeader?.data?.length === 0">
              0
            </ng-template>
          </span>
          <span>&nbsp;</span>
          <span>resultats)</span>
        </span>
      </div>
      <div class="hes-resum-widget__actions">
        <ng-container *ngIf="widgetHeader?.data?.length ?? 0 > 0">
          <em
            (click)="widgetHeaderPanel._toggle()"
            *ngIf="!widgetPanel.expanded"
            tp="Desplega la caixa de resultats de {{ widgetHeader?.title }}"
            variation="smallTooltip"
            title="Desplega la caixa de resultats de {{ widgetHeader?.title }}"
            attr.aria-label="Desplega la caixa de resultats de {{
              widgetHeader?.title
            }}"
            tabindex="1">
            <img
              alt="arrow down"
              src="assets/images/icon-24-px-chevron-down.svg" />
          </em>
          <em
            (click)="widgetHeaderPanel._toggle()"
            *ngIf="widgetPanel.expanded"
            tp="Contreu la caixa de resultats de {{ widgetHeader?.title }}"
            variation="smallTooltip"
            title="Contreu la caixa de resultats de {{ widgetHeader?.title }}"
            attr.aria-label="Contreu la caixa de resultats de {{
              widgetHeader?.title
            }}"
            tabindex="2">
            <img alt="arrow up" src="assets/images/icon-24-px-chevron-up.svg" />
          </em>
        </ng-container>
      </div>
    </mat-expansion-panel-header>
    <mat-card-content>
      <div
        *ngIf="widgetHeader?.data?.length ?? 0 > 0"
        class="hes-resum-widget__results">
        <ng-content></ng-content>
      </div>
    </mat-card-content>
  </mat-expansion-panel>
</mat-accordion>
