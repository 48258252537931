import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-local-loading',
  templateUrl: './local-loading.component.html',
  styleUrls: ['./local-loading.component.scss'],
})
export class LocalLoadingComponent {
  @Input() width?: number;
  @Input() height?: number;
  @Input() transparent = false;
}
