export enum ScreensEnum {
  MOTHER_PUERPERIUM = 'MOTHER_PUERPERIUM',
  NEWBORN_PUERPERIUM = 'NEWBORN_PUERPERIUM',
  MOTHER_BIRTH = 'MOTHER_BIRTH',
  FETUS_BIRTH = 'FETUS_BIRTH',
  NEWBORN_BIRTH = 'NEWBORN_BIRTH',
}

interface ScreenInfo {
  route: string;
  name: string;
  screenIndex: number;
}

export const screensInfoMapping: { [key in ScreensEnum]: ScreenInfo } = {
  [ScreensEnum.MOTHER_PUERPERIUM]: {
    route: '/obstetric/puerperium/mother',
    name: 'Puerperi Mare',
    screenIndex: 2,
  },
  [ScreensEnum.NEWBORN_PUERPERIUM]: {
    route: '/obstetric/puerperium/newborn',
    name: 'Puerperi Nadó',
    screenIndex: 2,
  },
  [ScreensEnum.MOTHER_BIRTH]: {
    route: '/obstetric/birth/mother',
    name: 'Part Mare',
    screenIndex: 1,
  },
  [ScreensEnum.FETUS_BIRTH]: {
    route: '/obstetric/birth/fetus',
    name: 'Part Fetus',
    screenIndex: 1,
  },
  [ScreensEnum.NEWBORN_BIRTH]: {
    route: '/obstetric/birth/newborn',
    name: 'Part Nadó',
    screenIndex: 1,
  },
};
