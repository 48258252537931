import { Component, Input } from '@angular/core';
import { HesDocumentService } from '@app/services/hes-document.service';
import { HesVirtualScrollerService } from '@app/shared/services/hes-virtual-scroller.service';
import { ResponseResumModel } from '../../models/response.model';

@Component({
  selector: 'app-widget-content',
  templateUrl: './widget-content.component.html',
  styleUrls: ['./widget-content.component.scss'],
  providers: [HesVirtualScrollerService],
})
export class WidgetContentComponent {
  @Input() public widgetHeight: number | string = 'auto';
  @Input() public isPrescriptionPage = false;
  @Input()
  public set widgetContentData(widgetContentData: ResponseResumModel[]) {
    if (widgetContentData) {
      this.getWidgetContentDataScrolled(widgetContentData);
      this.defWidgetMaxHeight(widgetContentData);
    }
  }

  public widgetMaxHeight?: number | string;
  public widgetContentDataScrolled?: any[];
  public itemHeight = 35;
  public prescripcionsTooltipMsg?: string;
  private visibleItems = 5;

  constructor(
    public hesVirtualScrollerService: HesVirtualScrollerService,
    public hesDocumentService: HesDocumentService
  ) {}

  public buildPrescripcionsTooltipMessage(
    pauta: string,
    indicacio: string
  ): string {
    let msg = 'Informació no disponible';
    if (pauta && indicacio) {
      msg = pauta + ' - ' + indicacio;
    } else if (pauta && !indicacio) {
      msg = pauta;
    } else if (!pauta && indicacio) {
      msg = indicacio;
    }

    return msg;
  }

  private getWidgetContentDataScrolled(
    widgetContentData: ResponseResumModel[]
  ): void {
    this.hesVirtualScrollerService.loadInitData(
      this.visibleItems,
      widgetContentData
    );
    this.widgetContentDataScrolled =
      this.hesVirtualScrollerService.dataScrolled;
  }

  private defWidgetMaxHeight(widgetContentData: ResponseResumModel[]): void {
    if (this.widgetHeight === 'auto') {
      this.widgetMaxHeight = this.itemHeight * this.visibleItems;
      this.widgetHeight = this.itemHeight * widgetContentData.length;
    } else {
      this.widgetMaxHeight = 'auto';
    }
  }
}
