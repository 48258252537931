import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ResponseBaseModel } from '../models/response.model';
import { IResponseBase } from '../interfaces/response.interface';
import { Config } from '@app/core/config/config';

@Injectable({
  providedIn: 'root',
})
export class ResumService {
  private _baseUrl: string = Config.api.baseUrl;

  constructor(private _http: HttpClient) {}

  public getTests(): Observable<ResponseBaseModel> {
    const url = `${this._baseUrl}/documents/resum/PV`;

    return this._http
      .get<IResponseBase>(url)
      .pipe(
        map((testsData: IResponseBase) => new ResponseBaseModel(testsData))
      );
  }

  public getPrescriptions(): Observable<ResponseBaseModel> {
    const url = `${this._baseUrl}/prescripcions/vigents`;

    return this._http
      .get<IResponseBase>(url)
      .pipe(
        map((testsData: IResponseBase) => new ResponseBaseModel(testsData))
      );
  }

  public getClinicalReports(): Observable<ResponseBaseModel> {
    const url = `${this._baseUrl}/documents/resum/IC`;

    return this._http
      .get<IResponseBase>(url)
      .pipe(
        map((testsData: IResponseBase) => new ResponseBaseModel(testsData))
      );
  }

  public getImmunizations(): Observable<ResponseBaseModel> {
    const url = `${this._baseUrl}/immunitzacions/resum`;

    return this._http
      .get<IResponseBase>(url)
      .pipe(
        map((testsData: IResponseBase) => new ResponseBaseModel(testsData))
      );
  }
}
