import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ScreensEnum, screensInfoMapping } from '@app/enums/screens.enum';
import { ObstetricService } from '@app/pages/obstetric/services/obstetric.service';

@Component({
  selector: 'app-exit-modal',
  templateUrl: './exit-modal.component.html',
  styleUrls: ['./exit-modal.component.scss'],
})
export class ExitModalComponent {
  screensInfoMapping = screensInfoMapping;

  constructor(
    @Inject(MAT_DIALOG_DATA) public screensOnEditMode: ScreensEnum[],
    public dialogRef: MatDialogRef<ExitModalComponent>,
    private _router: Router,
    private _obstetricService: ObstetricService
  ) {}

  navigateToScreen(route: string, screenIndex: number) {
    this._obstetricService.onScreenChange.next(screenIndex);
    this._router.navigate([route]);
    this.dialogRef.close(false);
  }

  onClose(discardChanges: boolean = false) {
    this.dialogRef.close(discardChanges);
  }
}
