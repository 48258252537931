import { ConfigurationLoaderService } from './configuration-loader.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Config } from '../config/config';
import { environment } from '../../../environments/environment';
import { LocationUtil } from '../utils/location.util';

const CONSOLE_STYLE = 'background: #222; color: #bada55';

@Injectable({
  providedIn: 'root',
})
export class HesConfigService {
  public get environmentName(): string {
    return this.innetEnvironmentName;
  }

  private innetEnvironmentName = 'DEVELOPMENT';

  public loadConfiguration(): Observable<any> {
    console.log('%cLOADING MFE-APP CONFIGURATION', CONSOLE_STYLE);

    if (environment.production) {
      this.innetEnvironmentName = 'PRODUCTION';
    }

    const selector = location.hostname;
    const baseURI: string = LocationUtil.getBaseHref();

    let baseUrl = baseURI;

    if (!baseUrl.endsWith('/')) {
      baseUrl = baseUrl + '/';
    }

    const cfgUrl = `${baseUrl}environments/${selector}.json`;

    console.log('%cCurrent baseUrl: [' + baseUrl + ']', CONSOLE_STYLE);

    console.log(
      '%cUsing configuration selector: [' + selector + ']',
      CONSOLE_STYLE
    );
    console.log('%cUsing configuration URL: [' + cfgUrl + ']', CONSOLE_STYLE);
    console.log(
      '%cStarting environment: [' + this.environmentName + ']',
      CONSOLE_STYLE
    );

    return ConfigurationLoaderService.bootstrap(cfgUrl, Config);
  }
}
