import { GenderEnum } from '@app/enums/gender.enum';
import {
  AccessInfo,
  AuthData,
  DadesProfessional,
  Identifier,
  LoginData,
  Paciente,
  User,
} from '@app/interfaces/auth.interface';
import { DateTime } from 'luxon';

export class LoginDataModel {
  public user: UserModel;
  public authData: AuthDataModel;

  constructor(data: LoginData) {
    this.user = new UserModel(data.user);
    this.authData = new AuthDataModel(data.authData);
  }
}

export class AuthDataModel {
  public access_token: string;
  public refresh_token: string;
  public dades_professional?: DadesProfessionalModel;

  constructor(data: AuthData) {
    this.access_token = data.access_token;
    this.refresh_token = data.refresh_token;
    this.dades_professional = data.dades_professional
      ? new DadesProfessionalModel(data.dades_professional)
      : data.dades_professional;
  }
}

export class DadesProfessionalModel {
  public LoginProf?: string;
  public NomProf?: string;
  public PrimerCognomProf?: string;
  public SegonCognomProf?: string;
  public NumColProf?: string;
  public CodiUABProf?: string;
  public CodiCentre?: string;
  public ClasseCentre?: string;
  public CodiServeiProf?: string;
  public CodiServeiAgen?: string;
  public CodiModulAgen?: string;
  public CodiUP?: string;
  public CodiEP?: string;
  public DNIProfCadira?: string;
  public LoginProfCadira?: string;
  public NumColProfCadira?: string;
  public CodiUABProfCadira?: string;
  public DelegacioSISAP?: string;
  public DniProf?: string;
  public CodiOficialCentre?: string;
  public TipusProf?: string;
  public NomProfCadira?: string;
  public PrimerCognomProfCadira?: string;
  public SegonCognomProfCadira?: string;
  public TipusProfCadira?: string;

  constructor(data: DadesProfessional) {
    this.LoginProf = data.LoginProf;
    this.NomProf = data.NomProf;
    this.PrimerCognomProf = data.PrimerCognomProf;
    this.SegonCognomProf = data.SegonCognomProf;
    this.NumColProf = data.NumColProf;
    this.CodiUABProf = data.CodiUABProf;
    this.CodiCentre = data.CodiCentre;
    this.ClasseCentre = data.ClasseCentre;
    this.CodiServeiProf = data.CodiServeiProf;
    this.CodiServeiAgen = data.CodiServeiAgen;
    this.CodiModulAgen = data.CodiModulAgen;
    this.CodiUP = data.CodiUP;
    this.CodiEP = data.CodiEP;
    this.DNIProfCadira = data.DNIProfCadira;
    this.LoginProfCadira = data.LoginProfCadira;
    this.NumColProfCadira = data.NumColProfCadira;
    this.CodiUABProfCadira = data.CodiUABProfCadira;
    this.DelegacioSISAP = data.DelegacioSISAP;
    this.DniProf = data.DniProf;
    this.CodiOficialCentre = data.CodiOficialCentre;
    this.TipusProf = data.TipusProf;
    this.NomProfCadira = data.NomProfCadira;
    this.PrimerCognomProfCadira = data.PrimerCognomProfCadira;
    this.SegonCognomProfCadira = data.SegonCognomProfCadira;
    this.TipusProfCadira = data.TipusProfCadira;
  }
}

export class UserModel {
  public nombre: string;
  public apellidos: string;
  public nombrePreferido?: string;
  public dni?: string;
  public up?: string;
  public numColegiat?: string;
  public imagen?: string;
  public dadesProfessional?: DadesProfessionalModel;
  public accessInfo: AccessInfoModel;
  public patient?: PacienteModel;
  public funcionalitats?: string;
  public hospitalName: string;

  constructor(data: User) {
    this.nombre = data.nombre;
    this.apellidos = data.apellidos;
    this.nombrePreferido = data.nombrePreferido;
    this.dni = data.dni;
    this.up = data.up;
    this.numColegiat = data.numColegiat;
    this.imagen = data.imagen;
    this.funcionalitats = data.funcionalitats;
    this.hospitalName = data.hospitalName;

    this.dadesProfessional = data.dadesProfessional
      ? new DadesProfessionalModel(data.dadesProfessional)
      : data.dadesProfessional;
    this.accessInfo = new AccessInfoModel(data.accessInfo);

    this.patient = data.patient
      ? new PacienteModel(data.patient)
      : data.patient;
  }
}

export class AccessInfoModel {
  public upCode: string;
  public traceUserGivenName: string;
  public identifier: IdentifierModel[];
  public traceUserFamilyName: string;
  public roleType: string;
  public centerCode: string;
  public traceUserId: string;
  public altIdentifier: IdentifierModel[];
  public epCode: string;
  public centerType: string;

  constructor(data: AccessInfo) {
    this.upCode = data.up_code;
    this.traceUserGivenName = data.trace_user_given_name;
    this.identifier = data.identifier.map(item => new IdentifierModel(item));
    this.traceUserFamilyName = data.trace_user_family_name;
    this.roleType = data.role_type;
    this.centerCode = data.center_code;
    this.traceUserId = data.trace_user_id;

    this.altIdentifier = data.alt_identifier.map(
      item => new IdentifierModel(item)
    );
    this.epCode = data.ep_code;
    this.centerType = data.center_type;
  }
}

export class IdentifierModel {
  public type: string;
  public value: string;

  constructor(data: Identifier) {
    this.type = data.type;
    this.value = data.value;
  }
}

export class PacienteModel {
  public name: string;
  public familyName: string;
  public mpiPatientId: string;
  public ehrId: string;
  public cip: string;
  public gender: GenderEnum;
  public communications: string[];
  public idObstetricProcess: string;
  public birthDate: string;
  public age: number;

  constructor(data: Paciente) {
    this.name = data.name;
    this.familyName = data.familyName;
    this.mpiPatientId = data.mpiPatientId;
    this.ehrId = data.ehrId;
    this.cip = data.cip;
    this.gender = data.gender;
    this.communications = data.communications;
    this.idObstetricProcess = data.idObstetricProcess;
    this.birthDate = data.birthDate;
    this.age = this.calculateAge(this.birthDate, 'ccc LLL dd HH:mm:ss z yyyy');
  }

  private calculateAge(birthDateString: string, format: string): number {
    const birthDate = DateTime.fromFormat(birthDateString, format);
    if (birthDate.isValid) {
      const today = DateTime.now();
      const age = today.diff(birthDate, 'years').years;

      return Math.floor(age);
    } else {
      return 0;
    }
  }
}
