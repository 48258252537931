import { tap, map } from 'rxjs/operators';
import { Config } from '@app/core/config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AuthData,
  LoginData,
  TokenResponse,
} from '@app/interfaces/auth.interface';
import { StorageService } from '@app/core/services/storage.service';
import {
  AuthDataModel,
  LoginDataModel,
  UserModel,
} from '@app/models/auth.model';
import { TokenInterceptorService } from '../token-interceptor.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private authData: AuthDataModel = {
    access_token: '',
    dades_professional: {},
    refresh_token: '',
  };

  private userData?: UserModel;

  private authDataSubject$: BehaviorSubject<AuthData> =
    new BehaviorSubject<AuthData>(this.authData);

  private userDataSubject$: BehaviorSubject<UserModel | undefined> =
    new BehaviorSubject<UserModel | undefined>(this.userData);

  private authDataPlanificatSubject$: BehaviorSubject<AuthData> =
    new BehaviorSubject<AuthData>(this.authData);

  get authDataObservable() {
    return this.authDataSubject$.asObservable();
  }

  get authDataPlanificatObservable() {
    return this.authDataPlanificatSubject$.asObservable();
  }

  get userDataObservable() {
    return this.userDataSubject$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private tokenInterceptor: TokenInterceptorService
  ) {}

  public login(
    encodedToken: string,
    refreshToken: string
  ): Observable<LoginDataModel> {
    const url = Config.api.baseUrl + Config.api.auth.login;
    const headers = new HttpHeaders({
      unauthenticated: 'true',
    });
    return this.http
      .post<LoginData>(
        url,
        { auth: encodedToken, refreshToken },
        { headers: headers }
      )
      .pipe(
        map((loginData: LoginData) => new LoginDataModel(loginData)),
        tap(authenticatedUser => {
          this.setUserData(authenticatedUser.user);
          this.setAuthData(authenticatedUser.authData);
          this.tokenInterceptor.setTokens(
            authenticatedUser.authData.access_token,
            authenticatedUser.authData.refresh_token
          );
        })
      );
  }

  public refreshToken(refreshToken: string) {
    const url = Config.api.baseUrl + Config.api.auth.refresh;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      unauthenticated: 'true',
    });
    const body = {
      refresh_token: refreshToken,
    };

    return this.http.post<TokenResponse>(url, body, { headers: headers }).pipe(
      map((res: TokenResponse) => {
        if (res.access_token && res.refresh_token)
          this.tokenInterceptor.setTokens(res.access_token, res.refresh_token);
        return res;
      })
    );
  }

  public setAuthData(authData: AuthDataModel) {
    this.authData = authData;
    this.saveLocalStorageAuthData(authData);
    this.authDataSubject$.next(this.authData);
  }

  public setUserData(userData?: UserModel) {
    this.userData = userData;
    this.userDataSubject$.next(this.userData);
  }

  public resetAuthData() {
    this.authData = {
      access_token: '',
      dades_professional: {},
      refresh_token: '',
    };

    this.setAuthData(this.authData);
  }

  public resetUserData() {
    this.userData = undefined;
    this.setUserData(this.userData);
  }

  public setAccessToken(key: string, expires?: number) {
    this.authData.access_token = key;
    this.saveLocalStorageAuthData(this.authData);
    this.authDataSubject$.next(this.authData);
  }
  public setRefreshToken(key: string, expires?: number) {
    this.authData.refresh_token = key;
    this.saveLocalStorageAuthData(this.authData);
    this.authDataSubject$.next(this.authData);
  }
  public getAccessToken() {
    return this.getAuthData().access_token;
  }

  public getRefreshToken() {
    return this.getAuthData().refresh_token;
  }

  public getAuthData() {
    return this.authData;
  }

  public saveLocalStorageAuthData(authData: AuthDataModel) {
    this.storageService.setItem('$auth', JSON.stringify(authData));
    this.storageService.setItem('access_token', authData?.access_token);

    this.storageService.setItem('refresh_token', authData?.refresh_token);
  }

  public getLocalStorageAuthData(): AuthData {
    const item = this.storageService.getItem('$auth');

    if (!item)
      return {
        access_token: '',
        dades_professional: {},
        refresh_token: '',
      };

    return JSON.parse(item);
  }

  public clearLocalStorageAuthData() {
    this.storageService.removeItem('$auth');
    this.storageService.removeItem('access_token');
    this.storageService.removeItem('refresh_token');
  }

  public isAuthenticated() {
    return this.userData !== undefined;
  }

  public decodeToken(token: string): AuthData | null {
    try {
      const data = atob(token);
      return JSON.parse(data);
    } catch (err: any) {
      return null;
    }
  }
}
