import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResumComponent } from './resum.component';
import { WidgetComponent } from './components/widget/widget.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TippyDirective } from '@ngneat/helipopper';
import { WidgetContentComponent } from './components/widget-content/widget-content.component';
import { HesVirtualScrollerModule } from '../hes-virtual-scroller/hes-virtual-scroller.module';

@NgModule({
  declarations: [ResumComponent, WidgetComponent, WidgetContentComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatProgressBarModule,
    TippyDirective,
    HesVirtualScrollerModule,
  ],
  exports: [ResumComponent],
})
export class ResumModule {}
