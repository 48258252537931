import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { B64Helper } from '@app/core/helpers';
import { HesDocumentDownloaderService } from './hes-document-downloader.service';

@Injectable({
  providedIn: 'root',
})
export class HesDocumentService {
  constructor(
    private hesDocDownloader: HesDocumentDownloaderService,
    private domSanitizer: DomSanitizer
  ) {}

  public openImg(cpc: string) {
    this.hesDocDownloader.downloadImg(cpc).subscribe(response => {
      const img: any[] = response.result.listaRespostaResum;
      window.open(img[0].img);
    });
  }

  public buildIconImg(base64: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      `assets/images/${base64}`
    );
  }

  public openPdf(cpc: string) {
    this.hesDocDownloader.downloadPdf(cpc).subscribe({
      next: response => {
        const doc: any[] = response.result.listaRespostaResum;
        if (response) {
          const base64: string = doc[0].doc;
          const blob: Blob = this.buildPdf(base64);
          if (blob) {
            this._openPdf(blob);
          }
        }
      },
      error: error => {
        console.error(error);
      },
    });
  }

  private buildPdf(base64: string): Blob {
    let arrayBuffer;
    let blob: Blob = new Blob();

    if (base64 && base64.startsWith('data')) {
      arrayBuffer = B64Helper.base64ToArrayBuffer(
        base64.split(',')[1]
      ) as ArrayBuffer;
    } else {
      arrayBuffer = B64Helper.base64ToArrayBuffer(base64) as ArrayBuffer;
    }

    if (arrayBuffer) {
      blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    }

    return blob;
  }

  private _openPdf(blob: Blob, nomDocument = 'document') {
    const file = blob;
    const evLink = document.createElement('a');

    const nav = window.navigator as any;

    if (nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(file, nomDocument + '.pdf');
    } else {
      // Otros navegadores (Chrome, Safari, Firefox, etc.)
      const fileURL = URL.createObjectURL(file);
      evLink.href = fileURL;
      evLink.target = '_blank';

      document.body.appendChild(evLink);
      evLink.click();
      window.open(fileURL);
      evLink.parentNode?.removeChild(evLink);
      window.URL.revokeObjectURL(fileURL);
    }
  }
}
