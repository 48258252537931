<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<cdk-virtual-scroll-viewport
  #scroller
  class="virtual-scroller"
  [itemSize]="itemSize"
  [ngStyle]="{
    cursor: loading ? 'wait' : 'default',
    contain: maxHeight ? 'layout paint' : 'strict',
    height: height,
    maxHeight: maxHeight
  }">
  <ng-content></ng-content>
</cdk-virtual-scroll-viewport>
<div
  *ngIf="mustShowGoUpButton && showGoUpButton"
  class="go-up-button-container">
  <img
    alt="back"
    src="assets/images/icon-32-px-back-to-top.svg"
    aria-label="Anar a inici"
    (click)="scrollToTop()"
    title="Anar a inici"
    class="clickable" />
</div>
