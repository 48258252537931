import { Component } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ResumModule } from '@app/shared/components/resum/resum.module';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [CommonModule, ResumModule],
})
export class SidebarComponent {}
