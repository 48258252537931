/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @angular-eslint/component-selector */
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { filter, map, pairwise, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'hes-virtual-scroller',
  templateUrl: './hes-virtual-scroller.component.html',
  styleUrls: ['./hes-virtual-scroller.component.scss'],
})
export class HesVirtualScrollerComponent implements OnInit, AfterViewInit {
  @Input() public itemSize = 160;
  @Input() public throttleTime = 200;
  @Input() public offset = 100;
  @Input() public loading = false;
  @Input() public height?: number | string;
  @Input() public maxHeight?: number | string;
  @Input() public showGoUpButton = false;

  public mustShowGoUpButton = false;

  @Output() public elementScrolled: EventEmitter<void> = new EventEmitter();
  @ViewChild('scroller') public scroller?: CdkVirtualScrollViewport;

  public isScrollerActive = false;

  constructor(private ngZone: NgZone) {}

  public ngOnInit(): void {
    if (this.height !== 'auto') {
      this.height = this.height + 'px';
    }

    if (this.maxHeight !== 'auto') {
      this.maxHeight = this.maxHeight + 'px';
    }
  }

  public ngAfterViewInit(): void {
    if (!this.scroller) return;

    const element = this.scroller.elementRef.nativeElement;

    this.isScrollerActive = element.scrollHeight > element.clientHeight;

    this.scroller
      .elementScrolled()
      .pipe(
        map(() => this.scroller!.measureScrollOffset('bottom')),
        pairwise(),
        filter(([y1, y2]) => y2 < y1 && y2 < this.offset),
        throttleTime(this.throttleTime)
      )
      .subscribe(() => {
        this.ngZone.run(() => {
          this.elementScrolled.emit();
        });
      });

    this.scroller
      .elementScrolled()
      .pipe(throttleTime(this.throttleTime))
      .subscribe(() => {
        this.ngZone.run(() => {
          const scrollOffsetToTop: number =
            this.scroller!.measureScrollOffset('top');
          this.mustShowGoUpButton = scrollOffsetToTop > 210;
        });
      });
  }

  public scrollToTop(): void {
    if (!this.scroller) return;
    this.scroller.scrollToIndex(0, 'smooth');
  }
}
