export const Config = {
  baseUrl: '',
  isProduction: false,
  mostraInputToken: false,
  api: {
    baseUrl: '***ACTUALIZAR EN EL FICHERO DE ENTORNO',
    auth: {
      login: '/auth/login',
      refresh: '/auth/refresh',
    },
    schedule: '/schedule',
  },
  diagnostic: {
    url: '***ACTUALIZAR EN EL FICHERO DE ENTORNO',
    origin: '***ACTUALIZAR EN EL FICHERO DE ENTORNO',
    apiKey: '***ACTUALIZAR EN EL FICHERO DE ENTORNO',
    user: '***ACTUALIZAR EN EL FICHERO DE ENTORNO',
  },
  formularisHes: {
    url: '***ACTUALIZAR EN EL FICHERO DE ENTORNO',
  },
};
