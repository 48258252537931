import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
  HttpClient,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, switchMap, map } from 'rxjs/operators';
import { StorageService } from './services/storage.service';
import { Config } from './config/config';
import { TokenResponse } from '@app/interfaces/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  private _accessToken = '';
  private _refreshToken = '';
  private _isRefreshingToken = false;

  constructor(
    private _storage: StorageService,
    public _http: HttpClient
  ) {}

  public setTokens(accesToken: string, refreshToken: string) {
    this._accessToken = accesToken;
    this._refreshToken = refreshToken;
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = this._setHeaderToken(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status !== HttpStatusCode.Unauthorized ||
          this._isRefreshingToken
        )
          throw new Error(error.message);

        this._isRefreshingToken = true;
        return this._refreshEcapToken().pipe(
          switchMap(() => {
            return next.handle(this._setHeaderToken(req));
          }),
          finalize(() => (this._isRefreshingToken = false))
        );
      })
    );
  }

  private _setHeaderToken(request: HttpRequest<any>): HttpRequest<any> {
    if (!this._accessToken) {
      this._getStorageTokens();
    }

    if (this._accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._accessToken}`,
        },
      });
    }

    return request;
  }

  private _refreshEcapToken() {
    const url = Config.api.baseUrl + Config.api.auth.refresh;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      unauthenticated: 'true',
    });
    const body = {
      refresh_token: this._refreshToken,
    };

    return this._http.post<TokenResponse>(url, body, { headers: headers }).pipe(
      map((res: TokenResponse) => {
        if (res.access_token && res.refresh_token)
          this.setTokens(res.access_token, res.refresh_token);
        return res;
      })
    );
  }

  private _getStorageTokens() {
    this._accessToken = this._storage.getItem('access_token');
    this._refreshToken = this._storage.getItem('refresh_token');
  }
}
