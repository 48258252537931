<!-- eslint-disable @angular-eslint/template/alt-text -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<hes-virtual-scroller
  #scroller
  (elementScrolled)="hesVirtualScrollerService.loadMoreData()"
  [height]="widgetHeight"
  [maxHeight]="widgetMaxHeight"
  [itemSize]="itemHeight">
  <ul role="table">
    <li
      role="row"
      class="hes-resum-widget-content__row"
      *ngFor="let data of widgetContentDataScrolled">
      <div
        role="cell"
        *ngIf="data.data"
        class="hes-resum-widget-content__data-column">
        <p class="hes-resum-widget-content__data">{{ data.data }}</p>
      </div>
      <div role="cell" class="hes-resum-widget-content__desc-column">
        <p class="hes-resum-widget-content__desc-container">
          <ng-container
            *ngIf="!isPrescriptionPage; else DescriptorPrescripcions">
            <span class="hes-resum-widget-content__desc">{{ data.desc }}</span>
          </ng-container>
          <ng-template #DescriptorPrescripcions>
            <span
              class="hes-resum-widget-content__desc"
              [tp]="
                buildPrescripcionsTooltipMessage(data.pauta, data.indicacio)
              "
              variation="smallTooltip"
              >{{ data.desc }}</span
            >
            <span
              *ngIf="data.tipusTractament"
              class="hes-resum-widget-content__label"
              >{{ data.tipusTractament }}</span
            >
            <span
              *ngIf="data.tipusPrescripcio"
              class="hes-resum-widget-content__label"
              >{{ data.tipusPrescripcio }}</span
            >
          </ng-template>
        </p>
        <div class="hes-resum-widget-content__actions">
          <button
            *ngIf="data.img"
            mat-icon-button
            tippy="Veure imatge"
            variation="smallTooltip"
            aria-label="Veure imatge"
            (click)="hesDocumentService.openImg(data.img)">
            <img
              *ngIf="!data.icona"
              alt="eco"
              src="assets/images/icon-32-px-eco.svg" />
            <img
              *ngIf="data.icona"
              alt="custom icon"
              [src]="hesDocumentService.buildIconImg(data.icona)" />
          </button>
          <button
            *ngIf="
              data.doc && data.desc !== 'Informe espirometries estructurat'
            "
            mat-icon-button
            tippy="Veure PDF"
            variation="smallTooltip"
            aria-label="Veure PDF"
            (click)="hesDocumentService.openPdf(data.doc)">
            <img alt="open pdf" src="assets/images/04-icon-32-px-pdf.svg" />
          </button>
        </div>
      </div>
    </li>
  </ul>
</hes-virtual-scroller>
