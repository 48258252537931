import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Config } from '@app/core/config/config';
import { ScreensEnum } from '@app/enums/screens.enum';
import { AuthService } from '@app/core/services/auth.service';
import { UserModel } from '@app/models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class ObstetricService implements OnDestroy {
  private _baseUrl: string = Config.api.baseUrl;
  private _storeDataMap: Map<string, any> = new Map();
  private subscriptions = new Subscription();
  private userData?: UserModel;

  public onSave: Subject<void> = new Subject();
  public onError: Subject<void> = new Subject();
  public onScreenChange: Subject<number> = new Subject();
  public stepperSubject: Subject<number> = new Subject();

  public screenEditModeSubject: BehaviorSubject<ScreensEnum[]> =
    new BehaviorSubject<ScreensEnum[]>([]);

  constructor(
    private _http: HttpClient,
    private _authService: AuthService
  ) {
    this.subscriptions.add(
      this._authService.userDataObservable.subscribe(
        userData => (this.userData = userData)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createObstetricProcessByCIP(cip: string) {
    const url = `${this._baseUrl}/obstetricProcess/create`;

    return this._http.post(url, cip, { responseType: 'text' });
  }

  getStoreData(screenId: ScreensEnum): any {
    return this._storeDataMap.get(screenId);
  }

  storeScreenData(screenId: ScreensEnum, data: any): void {
    if (this.userData) this._storeDataMap.set(screenId, data);

    this.screenEditModeSubject.next(this.getScreensOnEditMode());
  }

  deleteScreenData(screenId: ScreensEnum) {
    this._storeDataMap.delete(screenId);
    this.screenEditModeSubject.next(this.getScreensOnEditMode());
  }

  clearAllScreenData() {
    this._storeDataMap.clear();
    this.screenEditModeSubject.next(this.getScreensOnEditMode());
  }

  getScreensOnEditMode(): ScreensEnum[] {
    return Array.from(this._storeDataMap.keys()) as ScreensEnum[];
  }

  resetAllStores() {
    this._storeDataMap.clear();
  }
}
